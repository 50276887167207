.nav-link {
    color: #000;
    margin-right: 20px;
    text-decoration: none;
  }
  
  .nav-link-active {
    background-color: #5C21DB;
    text-decoration:underline;
    
  }