::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: rgba(94, 71, 221, 0.5)
}

::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(94, 71, 221, 0.5)
}

.container { 
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
  } 
  /* Override media queries for iPads */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Ensure this block overrides other media queries */
    
  
    /* Example: Make sure the main container keeps the laptop layout */
    .main-container {
      width: 100%;
      max-width: 1200px; /* Example: the width you use for laptop view */
      margin: 0 auto;
    }
  }
  body.ipad-layout .main-container {
    width: 100%;
    max-width: 1200px; /* Example width for laptop view */
    margin: 0 auto;
  }
  
  